import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { isFieldExpired } from "../../utils/isFieldExpired";
import { shouldAvailabilityUpdate } from "../../utils/shouldAvailabilityUpdate";
import { determineMenuStatus } from "../../utils/determineMenuStatus";
import { getFullMenu } from "../../actions/menu";
import { clearAllCartItems } from "../../actions/cart";
import { clearPaymentIntent } from "../../actions/payment";
import { clearCartDiscountCode } from "../../actions/cart";
import { SET_ORDERING_AVAILABLE } from "../../actions/types";

const CoreLogic = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const testMode = useSelector((state) => state.admin.testMode);
  const cartExpirationDate = useSelector(
    (state) => state.cart.cartExpirationDate
  );
  const paymentIntentExpiry = useSelector(
    (state) => state.payment.paymentIntentExpiry
  );
  const orderingAvailable = useSelector(
    (state) => state.menu.orderingAvailable
  );
  const activeMenu = useSelector((state) => state.menu.activeMenu);
  const { orderStartDate, orderByDate } = activeMenu;

  // Should cart items clear?
  const checkCartExpiration = () => {
    if (cartExpirationDate && isFieldExpired(cartExpirationDate)) {
      dispatch(clearAllCartItems());
    }
  };

  // Is ordering available?
  const checkOrderingAvailable = () => {
    if (
      shouldAvailabilityUpdate(orderingAvailable, orderStartDate, orderByDate)
    ) {
      dispatch({
        type: SET_ORDERING_AVAILABLE,
        payload: determineMenuStatus(orderStartDate, orderByDate),
      });
    }
  };

  // Should payment intent clear?
  const checkPaymentIntentExpiration = () => {
    if (paymentIntentExpiry && isFieldExpired(paymentIntentExpiry)) {
      dispatch(clearPaymentIntent());
    }
  };

  // Clear promo code from cart
  const clearDiscountFromCart = (location) => {
    if (location.pathname !== "/payment/checkout") {
      dispatch(clearCartDiscountCode());
    }
  };

  useEffect(() => {
    dispatch(getFullMenu(testMode));
  }, []);

  useEffect(() => {
    checkOrderingAvailable();
  }, [activeMenu]);

  useEffect(() => {
    clearDiscountFromCart(location);
    checkCartExpiration();
    checkPaymentIntentExpiration();
    checkOrderingAvailable();
  }, [location]);

  return <>{children}</>;
};

export default CoreLogic;
